"use strict";
(() => {
  var __create = Object.create;
  var __defProp = Object.defineProperty;
  var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
  var __getOwnPropNames = Object.getOwnPropertyNames;
  var __getProtoOf = Object.getPrototypeOf;
  var __hasOwnProp = Object.prototype.hasOwnProperty;
  var __esm = (fn, res) => function __init() {
    return fn && (res = (0, fn[__getOwnPropNames(fn)[0]])(fn = 0)), res;
  };
  var __commonJS = (cb, mod) => function __require() {
    return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
  };
  var __export = (target, all) => {
    for (var name in all)
      __defProp(target, name, { get: all[name], enumerable: true });
  };
  var __copyProps = (to, from, except, desc2) => {
    if (from && typeof from === "object" || typeof from === "function") {
      for (let key of __getOwnPropNames(from))
        if (!__hasOwnProp.call(to, key) && key !== except)
          __defProp(to, key, { get: () => from[key], enumerable: !(desc2 = __getOwnPropDesc(from, key)) || desc2.enumerable });
    }
    return to;
  };
  var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
    // If the importer is in node compatibility mode or this is not an ESM
    // file that has been converted to a CommonJS file using a Babel-
    // compatible transform (i.e. "__esModule" has not been set), then set
    // "default" to the CommonJS "module.exports" for node compatibility.
    isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
    mod
  ));
  var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

  // core.ts
  var core_exports = {};
  __export(core_exports, {
    appendChild: () => appendChild,
    createHTMLElement: () => createHTMLElement,
    createProxy: () => createProxy,
    createSVGElement: () => createSVGElement,
    createText: () => createText,
    fragment: () => fragment,
    genCreateHTMLElement: () => genCreateHTMLElement,
    genCreateSVGElement: () => genCreateSVGElement,
    h: () => createHTMLElement,
    html: () => createHTMLElement,
    s: () => createSVGElement,
    svg: () => createSVGElement,
    t: () => createText,
    text: () => createText,
    watch: () => watch
  });
  function fragment(nodes) {
    const fragment2 = document.createDocumentFragment();
    for (const node of nodes) {
      appendChild(fragment2, node);
    }
    return fragment2;
  }
  function createText(value = "") {
    const node = document.createTextNode(value);
    return createProxy(node);
  }
  function watch(fn, options) {
    watchFn = fn;
    watchOptions = options;
    fn();
    watchFn = void 0;
    watchOptions = void 0;
  }
  function resetTimeout() {
    for (let fn of resetFns) {
      try {
        fn();
      } catch (error) {
        console.error(error);
      }
    }
    resetFns.clear();
    resetTimer = null;
  }
  function toPropertyKey(p2) {
    return p2 === "value" || p2 === "valueAsNumber" || p2 === "valueAsDate" ? "value" : p2;
  }
  function createProxy(node) {
    if (proxySymbol in node) {
      return node[proxySymbol];
    }
    const deps = /* @__PURE__ */ new Map();
    const proxy = new Proxy(node, {
      get(target, p2, receiver) {
        var _a, _b;
        const listenEventType = (_a = watchOptions == null ? void 0 : watchOptions.listen) != null ? _a : "input";
        if (listenEventType && watchFn && typeof p2 === "string") {
          const key = toPropertyKey(p2);
          const fn = watchFn;
          if (key === "value" || key === "checked") {
            target.addEventListener(
              listenEventType,
              // wrap the function to avoid the default behavior be cancelled
              // if the inline-function returns false
              () => fn()
            );
            (_b = target.form) == null ? void 0 : _b.addEventListener(
              "reset",
              () => {
                resetFns.add(fn);
                if (resetTimer)
                  return;
                resetTimer = setTimeout(resetTimeout);
              }
            );
          }
          let fns = deps.get(key);
          if (!fns) {
            fns = /* @__PURE__ */ new Set();
            deps.set(key, fns);
          }
          fns.add(fn);
        }
        const value = target[p2];
        if (typeof value === "function") {
          return value.bind(target);
        }
        return value;
      },
      set(target, p2, value, receiver) {
        target[p2] = value;
        if (typeof p2 === "string") {
          const key = toPropertyKey(p2);
          const fns = deps.get(key);
          if (fns) {
            for (const fn of fns) {
              fn();
            }
          }
        }
        return true;
      }
    });
    return node[proxySymbol] = Object.assign(proxy, { node });
  }
  function genCreateHTMLElement(tagName) {
    return (props_or_children, children) => Array.isArray(props_or_children) ? createHTMLElement(tagName, void 0, props_or_children) : createHTMLElement(
      tagName,
      props_or_children,
      children
    );
  }
  function genCreateSVGElement(tagName) {
    return (props_or_children, children) => Array.isArray(props_or_children) ? createSVGElement(tagName, void 0, props_or_children) : createSVGElement(
      tagName,
      props_or_children,
      children
    );
  }
  function createHTMLElement(tagName, props, children) {
    const node = document.createElement(tagName);
    applyAttrs(node, props, children);
    return createProxy(node);
  }
  function createSVGElement(tagName, props, children) {
    const node = document.createElementNS("http://www.w3.org/2000/svg", tagName);
    applyAttrs(node, props, children);
    return createProxy(node);
  }
  function applyAttrs(node, props, children) {
    if (props) {
      for (let p2 in props) {
        let value = props[p2];
        if (value !== null && typeof value === "object" && p2 in node) {
          Object.assign(node[p2], value);
        } else {
          ;
          node[p2] = value;
        }
      }
    }
    if (children) {
      for (const child of children) {
        appendChild(node, child);
      }
    }
  }
  function appendChild(parent, child) {
    if (typeof child == "string") {
      parent.appendChild(document.createTextNode(child));
    } else if (typeof child == "number") {
      parent.appendChild(document.createTextNode(String(child)));
    } else if ("node" in child) {
      parent.appendChild(child.node);
    } else {
      parent.appendChild(child);
    }
  }
  var watchFn, watchOptions, resetTimer, resetFns, proxySymbol;
  var init_core = __esm({
    "core.ts"() {
      "use strict";
      resetTimer = null;
      resetFns = /* @__PURE__ */ new Set();
      proxySymbol = Symbol("proxy");
    }
  });

  // helpers.ts
  var helpers_exports = {};
  __export(helpers_exports, {
    a: () => a,
    aSVG: () => aSVG,
    abbr: () => abbr,
    address: () => address,
    animate: () => animate,
    animateMotion: () => animateMotion,
    animateTransform: () => animateTransform,
    area: () => area,
    article: () => article,
    aside: () => aside,
    audio: () => audio,
    b: () => b,
    base: () => base,
    bdi: () => bdi,
    bdo: () => bdo,
    blockquote: () => blockquote,
    body: () => body,
    br: () => br,
    button: () => button,
    canvas: () => canvas,
    caption: () => caption,
    circle: () => circle,
    cite: () => cite,
    clipPath: () => clipPath,
    code: () => code,
    col: () => col,
    colgroup: () => colgroup,
    createElementFunctions: () => createElementFunctions,
    createHTMLElementFunctions: () => createHTMLElementFunctions,
    createSVGElementFunctions: () => createSVGElementFunctions,
    data: () => data,
    datalist: () => datalist,
    dd: () => dd,
    defs: () => defs,
    del: () => del,
    desc: () => desc,
    details: () => details,
    dfn: () => dfn,
    dialog: () => dialog,
    div: () => div,
    dl: () => dl,
    dt: () => dt,
    ellipse: () => ellipse,
    em: () => em,
    embed: () => embed,
    feBlend: () => feBlend,
    feColorMatrix: () => feColorMatrix,
    feComponentTransfer: () => feComponentTransfer,
    feComposite: () => feComposite,
    feConvolveMatrix: () => feConvolveMatrix,
    feDiffuseLighting: () => feDiffuseLighting,
    feDisplacementMap: () => feDisplacementMap,
    feDistantLight: () => feDistantLight,
    feDropShadow: () => feDropShadow,
    feFlood: () => feFlood,
    feFuncA: () => feFuncA,
    feFuncB: () => feFuncB,
    feFuncG: () => feFuncG,
    feFuncR: () => feFuncR,
    feGaussianBlur: () => feGaussianBlur,
    feImage: () => feImage,
    feMerge: () => feMerge,
    feMergeNode: () => feMergeNode,
    feMorphology: () => feMorphology,
    feOffset: () => feOffset,
    fePointLight: () => fePointLight,
    feSpecularLighting: () => feSpecularLighting,
    feSpotLight: () => feSpotLight,
    feTile: () => feTile,
    feTurbulence: () => feTurbulence,
    figcaption: () => figcaption,
    figure: () => figure,
    filter: () => filter,
    footer: () => footer,
    foreignObject: () => foreignObject,
    form: () => form,
    g: () => g,
    h1: () => h1,
    h2: () => h2,
    h3: () => h3,
    h4: () => h4,
    h5: () => h5,
    h6: () => h6,
    head: () => head,
    header: () => header,
    hgroup: () => hgroup,
    hr: () => hr,
    htmlElement: () => htmlElement,
    i: () => i,
    iframe: () => iframe,
    image: () => image,
    img: () => img,
    input: () => input,
    ins: () => ins,
    kbd: () => kbd,
    label: () => label,
    legend: () => legend,
    li: () => li,
    line: () => line,
    linearGradient: () => linearGradient,
    link: () => link,
    main: () => main,
    map: () => map,
    mark: () => mark,
    marker: () => marker,
    mask: () => mask,
    menu: () => menu,
    meta: () => meta,
    metadata: () => metadata,
    meter: () => meter,
    mpath: () => mpath,
    nav: () => nav,
    noscript: () => noscript,
    object: () => object,
    ol: () => ol,
    optgroup: () => optgroup,
    option: () => option,
    output: () => output,
    p: () => p,
    path: () => path,
    pattern: () => pattern,
    picture: () => picture,
    polygon: () => polygon,
    polyline: () => polyline,
    pre: () => pre,
    progress: () => progress,
    q: () => q,
    radialGradient: () => radialGradient,
    rect: () => rect,
    rp: () => rp,
    rt: () => rt,
    ruby: () => ruby,
    sElement: () => sElement,
    samp: () => samp,
    scriptElement: () => scriptElement,
    scriptSVG: () => scriptSVG,
    section: () => section,
    select: () => select,
    set: () => set,
    slot: () => slot,
    small: () => small,
    source: () => source,
    span: () => span,
    stop: () => stop,
    strong: () => strong,
    styleElement: () => styleElement,
    styleSVG: () => styleSVG,
    sub: () => sub,
    summary: () => summary,
    sup: () => sup,
    svgSVG: () => svgSVG,
    switchSVG: () => switchSVG,
    symbol: () => symbol,
    table: () => table,
    tbody: () => tbody,
    td: () => td,
    template: () => template,
    textPath: () => textPath,
    textSVG: () => textSVG,
    textarea: () => textarea,
    tfoot: () => tfoot,
    th: () => th,
    thead: () => thead,
    time: () => time,
    titleElement: () => titleElement,
    titleSVG: () => titleSVG,
    tr: () => tr,
    track: () => track,
    tspan: () => tspan,
    u: () => u,
    ul: () => ul,
    use: () => use,
    varElement: () => varElement,
    video: () => video,
    view: () => view,
    wbr: () => wbr
  });
  var createHTMLElementFunctions, createSVGElementFunctions, createElementFunctions, a, abbr, address, area, article, aside, audio, b, base, bdi, bdo, blockquote, body, br, button, canvas, caption, cite, code, col, colgroup, data, datalist, dd, del, details, dfn, dialog, div, dl, dt, em, embed, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, head, header, hgroup, hr, htmlElement, i, iframe, img, input, ins, kbd, label, legend, li, link, main, map, mark, menu, meta, meter, nav, noscript, object, ol, optgroup, option, output, p, picture, pre, progress, q, rp, rt, ruby, sElement, samp, scriptElement, section, select, slot, small, source, span, strong, styleElement, sub, summary, sup, table, tbody, td, template, textarea, tfoot, th, thead, time, titleElement, tr, track, u, ul, varElement, video, wbr, aSVG, animate, animateMotion, animateTransform, circle, clipPath, defs, desc, ellipse, feBlend, feColorMatrix, feComponentTransfer, feComposite, feConvolveMatrix, feDiffuseLighting, feDisplacementMap, feDistantLight, feDropShadow, feFlood, feFuncA, feFuncB, feFuncG, feFuncR, feGaussianBlur, feImage, feMerge, feMergeNode, feMorphology, feOffset, fePointLight, feSpecularLighting, feSpotLight, feTile, feTurbulence, filter, foreignObject, g, image, line, linearGradient, marker, mask, metadata, mpath, path, pattern, polygon, polyline, radialGradient, rect, scriptSVG, set, stop, styleSVG, svgSVG, switchSVG, symbol, textSVG, textPath, titleSVG, tspan, use, view;
  var init_helpers = __esm({
    "helpers.ts"() {
      "use strict";
      init_core();
      createHTMLElementFunctions = new Proxy(
        {},
        {
          get(target, p2, receiver) {
            return genCreateHTMLElement(p2);
          }
        }
      );
      createSVGElementFunctions = new Proxy(
        {},
        {
          get(target, p2, receiver) {
            return genCreateSVGElement(p2);
          }
        }
      );
      createElementFunctions = {
        html: createHTMLElementFunctions,
        svg: createSVGElementFunctions
      };
      ({
        a,
        abbr,
        address,
        area,
        article,
        aside,
        audio,
        b,
        base,
        bdi,
        bdo,
        blockquote,
        body,
        br,
        button,
        canvas,
        caption,
        cite,
        code,
        col,
        colgroup,
        data,
        datalist,
        dd,
        del,
        details,
        dfn,
        dialog,
        div,
        dl,
        dt,
        em,
        embed,
        figcaption,
        figure,
        footer,
        form,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        head,
        header,
        hgroup,
        hr,
        html: htmlElement,
        i,
        iframe,
        img,
        input,
        ins,
        kbd,
        label,
        legend,
        li,
        link,
        main,
        map,
        mark,
        menu,
        meta,
        meter,
        nav,
        noscript,
        object,
        ol,
        optgroup,
        option,
        output,
        p,
        picture,
        pre,
        progress,
        q,
        rp,
        rt,
        ruby,
        s: sElement,
        samp,
        script: scriptElement,
        section,
        select,
        slot,
        small,
        source,
        span,
        strong,
        style: styleElement,
        sub,
        summary,
        sup,
        table,
        tbody,
        td,
        template,
        textarea,
        tfoot,
        th,
        thead,
        time,
        title: titleElement,
        tr,
        track,
        u,
        ul,
        var: varElement,
        video,
        wbr
      } = createHTMLElementFunctions);
      ({
        a: aSVG,
        animate,
        animateMotion,
        animateTransform,
        circle,
        clipPath,
        defs,
        desc,
        ellipse,
        feBlend,
        feColorMatrix,
        feComponentTransfer,
        feComposite,
        feConvolveMatrix,
        feDiffuseLighting,
        feDisplacementMap,
        feDistantLight,
        feDropShadow,
        feFlood,
        feFuncA,
        feFuncB,
        feFuncG,
        feFuncR,
        feGaussianBlur,
        feImage,
        feMerge,
        feMergeNode,
        feMorphology,
        feOffset,
        fePointLight,
        feSpecularLighting,
        feSpotLight,
        feTile,
        feTurbulence,
        filter,
        foreignObject,
        g,
        image,
        line,
        linearGradient,
        marker,
        mask,
        metadata,
        mpath,
        path,
        pattern,
        polygon,
        polyline,
        radialGradient,
        rect,
        script: scriptSVG,
        set,
        stop,
        style: styleSVG,
        svg: svgSVG,
        switch: switchSVG,
        symbol,
        text: textSVG,
        textPath,
        title: titleSVG,
        tspan,
        use,
        view
      } = createSVGElementFunctions);
    }
  });

  // selector.ts
  var selector_exports = {};
  __export(selector_exports, {
    queryAllElementProxies: () => queryAllElementProxies,
    queryAllElements: () => queryAllElements,
    queryElement: () => queryElement,
    queryElementProxies: () => queryElementProxies,
    queryElementProxy: () => queryElementProxy,
    queryElements: () => queryElements
  });
  function queryElement(selector, parent = document.body) {
    let element = parent.querySelector(selector);
    if (!element)
      throw new Error("failed to find element, selector: " + selector);
    return element;
  }
  function queryElementProxy(selector, parent) {
    return createProxy(queryElement(selector, parent));
  }
  function queryAllElements(selector, parent = document.body) {
    let elements = parent.querySelectorAll(selector);
    return Array.from(elements);
  }
  function queryAllElementProxies(selector, parent = document.body) {
    let elements = parent.querySelectorAll(selector);
    return Array.from(elements, (element) => createProxy(element));
  }
  function queryElements(selectors, parent = document.body) {
    let object2 = {};
    for (let [key, selector] of Object.entries(selectors)) {
      object2[key] = queryElement(selector, parent);
    }
    return object2;
  }
  function queryElementProxies(selectors, parent = document.body) {
    let object2 = {};
    for (let [key, selector] of Object.entries(selectors)) {
      object2[key] = queryElementProxy(selector, parent);
    }
    return object2;
  }
  var init_selector = __esm({
    "selector.ts"() {
      "use strict";
      init_core();
    }
  });

  // index.js
  var require_dom_proxy = __commonJS({
    "index.js"(exports) {
      "use strict";
      var __createBinding = exports && exports.__createBinding || (Object.create ? function(o, m, k, k2) {
        if (k2 === void 0)
          k2 = k;
        var desc2 = Object.getOwnPropertyDescriptor(m, k);
        if (!desc2 || ("get" in desc2 ? !m.__esModule : desc2.writable || desc2.configurable)) {
          desc2 = { enumerable: true, get: function() {
            return m[k];
          } };
        }
        Object.defineProperty(o, k2, desc2);
      } : function(o, m, k, k2) {
        if (k2 === void 0)
          k2 = k;
        o[k2] = m[k];
      });
      var __exportStar = exports && exports.__exportStar || function(m, exports2) {
        for (var p2 in m)
          if (p2 !== "default" && !Object.prototype.hasOwnProperty.call(exports2, p2))
            __createBinding(exports2, m, p2);
      };
      Object.defineProperty(exports, "__esModule", { value: true });
      __exportStar((init_core(), __toCommonJS(core_exports)), exports);
      __exportStar((init_helpers(), __toCommonJS(helpers_exports)), exports);
      __exportStar((init_selector(), __toCommonJS(selector_exports)), exports);
    }
  });

  // browser.ts
  var domProxy = __toESM(require_dom_proxy());
  window.domProxy = domProxy;
})();
